
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "strategy-overview",
  components: {},
  props: {
    id: Number,
    progress: Number,
    statusDataBadgeColor: String,
    statusDataColor: String,
    tags: Array,
    icon: String,
    title: String,
    description: String,
    spend: Number,
    followers: Number,
    users: Array
  },
  setup(props) {
    const getDescription = computed(() => {
      return props.description;
    });

    const getFollowers = computed(() => {
      return props.followers;
    });

    const getTags = computed(() => {
      return props.tags ?? [];
    });

    const getStatusDataBadgeColor = computed(() => {
      return props.statusDataBadgeColor
        ? props.statusDataBadgeColor
        : "badge-secondary";
    });

    const getStatusDataColor = computed(() => {
      return props.statusDataColor ? props.statusDataColor : "bg-secondary";
    });

    return {
      getDescription,
      getFollowers,
      getTags,
      getStatusDataBadgeColor,
      getStatusDataColor
    };
  }
});
