<template>
  <!--begin::Card-->
  <router-link :to="{  name: 'strategy', params: { id: id } }" class="card border border-2 border-gray-300 border-hover">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-9">
      <!--begin::Card Title-->
      <div class="card-title m-0">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px w-50px bg-light">
          <img :src="icon" alt="image" class="p-3" />
        </div>
        <!--end::Avatar-->
      </div>
      <!--end::Car Title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <span
          v-for="tag in getTags"
          :class="getStatusDataBadgeColor"
          class="badge fw-bolder ms-3 px-4 py-3"
        >{{ tag }}</span
        >
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end:: Card header-->

    <!--begin:: Card body-->
    <div class="card-body p-9">
      <!--begin::Name-->
      <div class="fs-3 fw-bolder text-dark">
        {{ title }}
      </div>
      <!--end::Name-->

      <!--begin::Description-->
      <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7">
        {{ getDescription }}
      </p>
      <!--end::Description-->

      <!--begin::Info-->
      <div class="d-flex flex-wrap mb-5">
        <!--begin::Due-->
        <div
          class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"
        >
          <div class="fs-6 text-gray-800 fw-bolder">{{ $filters.shortMoney(spend) }}</div>
          <div class="fw-bold text-gray-400">Spend</div>
        </div>
        <!--end::Due-->

        <!--begin::Budget-->
        <div
          class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
        >
          <div class="fs-6 text-gray-800 fw-bolder">{{ getFollowers }}</div>
          <div class="fw-bold text-gray-400">Followers</div>
        </div>
        <!--end::Budget-->
      </div>
      <!--end::Info-->

      <!--begin::Progress-->
<!--      <div-->
<!--        class="h-4px w-100 bg-light mb-5"-->
<!--        data-bs-toggle="tooltip"-->
<!--        :title="`This project ${progress}% completed`"-->
<!--      >-->
<!--        <div-->
<!--          :class="getStatusDataColor"-->
<!--          class="rounded h-4px"-->
<!--          role="progressbar"-->
<!--          :style="{ width: progress + `%` }"-->
<!--          :aria-valuenow="progress"-->
<!--          aria-valuemin="0"-->
<!--          aria-valuemax="100"-->
<!--        ></div>-->
<!--      </div>-->
      <!--end::Progress-->

    </div>
    <!--end:: Card body-->
  </router-link>
  <!--end::Card-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "strategy-overview",
  components: {},
  props: {
    id: Number,
    progress: Number,
    statusDataBadgeColor: String,
    statusDataColor: String,
    tags: Array,
    icon: String,
    title: String,
    description: String,
    spend: Number,
    followers: Number,
    users: Array
  },
  setup(props) {
    const getDescription = computed(() => {
      return props.description;
    });

    const getFollowers = computed(() => {
      return props.followers;
    });

    const getTags = computed(() => {
      return props.tags ?? [];
    });

    const getStatusDataBadgeColor = computed(() => {
      return props.statusDataBadgeColor
        ? props.statusDataBadgeColor
        : "badge-secondary";
    });

    const getStatusDataColor = computed(() => {
      return props.statusDataColor ? props.statusDataColor : "bg-secondary";
    });

    return {
      getDescription,
      getFollowers,
      getTags,
      getStatusDataBadgeColor,
      getStatusDataColor
    };
  }
});
</script>
