
import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import StrategyOverview from "@/components/strategies/StrategyOverview.vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "strategies",
  components: {
    StrategyOverview,
  },
  data() {
    return {}
  },
  mounted() {
    setCurrentPageTitle("Strategies");
    this.fetchStrategies({force: true});
  },
  computed: {
    ...mapGetters({
      strategies: 'strategies/list',
    })
  },
  methods: {
    ...mapActions({
      fetchStrategies: 'strategies/fetch',
    }),
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Strategies", []);
    });
  }
});
