<template>
  <!--begin::Toolbar-->
  <div class="d-flex flex-wrap flex-stack my-5">
    <!--begin::Heading-->
    <h2 class="fs-2 fw-bold my-2">
      Strategies
      <span class="fs-6 text-gray-400 ms-1">by Status</span>
    </h2>
    <!--end::Heading-->

    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
      <!--begin::Select wrapper-->
      <div class="m-0">
        <!--begin::Select-->
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm fw-bolder w-125px"
        >
          <option value="Active" selected>Active</option>
          <option value="Approved">In Progress</option>
          <option value="Declined">To Do</option>
          <option value="In Progress">Completed</option>
        </select>
        <!--end::Select-->
      </div>
      <!--end::Select wrapper-->
    </div>
    <!--end::Controls-->
  </div>
  <!--end::Toolbar-->

  <!--begin::Row-->
  <div class="row g-6 g-xl-9">
    <!--begin::Col-->
    <div class="col-md-6 col-xl-4" v-for="(strategy, index) in strategies" v-bind:key="index">
      <StrategyOverview
        :id="strategy.id"
        :progress="strategy.progress"
        :title="strategy.title"
        :icon="strategy.icon"
        :description="strategy.description"
        :tags="strategy.tags"
        :spend="strategy.spend"
        :followers="strategy.followers"
      ></StrategyOverview>
    </div>
    <!--end::Col-->

    <!--begin::Pagination-->
    <div class="d-flex flex-stack flex-wrap pt-10">
      <div class="fs-6 fw-bold text-gray-700">
        Showing 1 to 10 of 50 entries
      </div>

      <!--begin::Pages-->
      <ul class="pagination">
        <li class="page-item previous">
          <a href="#" class="page-link"><i class="previous"></i></a>
        </li>

        <li class="page-item active">
          <a href="#" class="page-link">1</a>
        </li>

        <li class="page-item">
          <a href="#" class="page-link">2</a>
        </li>

        <li class="page-item">
          <a href="#" class="page-link">3</a>
        </li>

        <li class="page-item">
          <a href="#" class="page-link">4</a>
        </li>

        <li class="page-item">
          <a href="#" class="page-link">5</a>
        </li>

        <li class="page-item">
          <a href="#" class="page-link">6</a>
        </li>

        <li class="page-item next">
          <a href="#" class="page-link"><i class="next"></i></a>
        </li>
      </ul>
      <!--end::Pages-->
    </div>
    <!--end::Pagination-->
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import StrategyOverview from "@/components/strategies/StrategyOverview.vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "strategies",
  components: {
    StrategyOverview,
  },
  data() {
    return {}
  },
  mounted() {
    setCurrentPageTitle("Strategies");
    this.fetchStrategies({force: true});
  },
  computed: {
    ...mapGetters({
      strategies: 'strategies/list',
    })
  },
  methods: {
    ...mapActions({
      fetchStrategies: 'strategies/fetch',
    }),
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Strategies", []);
    });
  }
});
</script>
